import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

const CURRENT_DATE = dayjs();
const CURRENT_YEAR = new Date().getFullYear();

// ===== Questionnaire =====
export const QUESTIONNAIRE_CLOSE_DATE_SINGAPORE = dayjs(
  `September 15, ${CURRENT_YEAR} 23:59:00 UTC+8`,
)
  .tz('Australia/Perth')
  .local();
// For the college procrastinators.
export const QUESTIONNAIRE_DISABLED_DATE_SINGAPORE = dayjs(
  `September 15, ${CURRENT_YEAR} 23:59:00 UTC+8`,
)
  .tz('Australia/Perth')
  .local();
export const QUESTIONNAIRE_CLOSE_DATE_GLOBAL = dayjs(`February 10, ${CURRENT_YEAR}, 23:59:00 UTC-5`)
  .tz('America/Los_Angeles')
  .local();
// For the college procrastinators.
export const QUESTIONNAIRE_DISABLED_DATE_GLOBAL = dayjs(
  `February 11, ${CURRENT_YEAR}, 02:59:00 UTC-5`,
)
  .tz('America/Los_Angeles')
  .local();

// ===== Launch =====
export const LAUNCH_DATE_SINGAPORE = dayjs(`August 30 ${CURRENT_YEAR} 20:00:00 UTC+8`)
  .tz('Australia/Perth')
  .local();
const CURRENT_VALENTINES = dayjs(`Februrary 14, ${CURRENT_YEAR}, 20:00:00 UTC-5`)
  .tz('America/Los_Angeles')
  .local();
const UPCOMING_GLOBAL_LAUNCH_YEAR = CURRENT_DATE.isAfter(CURRENT_VALENTINES)
  ? CURRENT_YEAR + 1
  : CURRENT_YEAR;
export const LAUNCH_DATE_GLOBAL = dayjs(
  `January 29, ${UPCOMING_GLOBAL_LAUNCH_YEAR}, 20:00:00 UTC-5`,
)
  .tz('America/Los_Angeles')
  .local();

// ===== Match Results =====
export const MATCH_RESULTS_DATE_SINGAPORE = '19th September';
export const MATCH_RESULTS_DATE_GLOBAL = '14th February (ET)';
